import { Controller } from '@hotwired/stimulus'
import { createConsumer } from '@rails/actioncable'
import Swal from 'sweetalert2'

export default class extends Controller {
  static targets = [
    'form',
    'modal',
    'checkbox',
    'toggleSwitch',
    'fileName',
    'fileInputWrapper',
    'slider',
    'advancedSettings',
    'advancedSettingsIcon',
    'searchIdentityCheckbox',
    'searchIdentityToggleSwitch',
    'audioIntelligenceCheckbox',
    'audioIntelligenceToggleSwitch',
    'detectsList',
  ]
  settingsChanged = { frame_length: false, sensitivity: false, isolate_voice: false, search_identity: false, audio_intelligence: false }

  pendingUpdates: { [key: string]: { identitySearch?: any; audioIntelligence?: any } } = {}
  expectedResults: { [key: string]: { identitySearch: boolean; audioIntelligence: boolean } } = {}
  filename: string | null = null
  audio_url: string | null = null

  declare readonly fileNameTarget: HTMLSpanElement
  declare readonly fileInputWrapperTarget: HTMLDivElement
  declare readonly modalTarget: HTMLDivElement
  declare readonly checkboxTarget: HTMLInputElement
  declare readonly formTarget: HTMLFormElement
  declare readonly toggleSwitchTarget: HTMLDivElement
  declare readonly advancedSettingsTarget: HTMLDivElement
  declare readonly advancedSettingsIconTarget: HTMLElement
  declare readonly searchIdentityCheckboxTarget: HTMLInputElement
  declare readonly searchIdentityToggleSwitchTarget: HTMLDivElement
  declare readonly audioIntelligenceCheckboxTarget: HTMLInputElement
  declare readonly audioIntelligenceToggleSwitchTarget: HTMLDivElement
  declare readonly detectsListTarget: HTMLUListElement

  private channel: any

  connect(): void {
    this.channel = createConsumer().subscriptions.create('DetectChannel', {
      connected() {
        // Called when the subscription is ready for use on the server
      },
      disconnected() {
        // Called when the subscription has been terminated by the server
      },
      received: (data: any) => {
        // Called when there's incoming data on the websocket for this channel
        this.handleWebSocketMessage(data)
      },
    })

    this.advancedSettingsTarget.querySelectorAll('input').forEach((input) => {
      input.addEventListener('change', () => {
        const key = input.name as keyof typeof this.settingsChanged
        this.settingsChanged[key] = true
      })
    })
  }

  handleWebSocketMessage(data: { action: string; detect?: any; detect_id?: string; identity_search?: any; audio_intelligence?: any }) {
    console.log(data)
    switch (data.action) {
      case 'update_detect':
        if (data.detect) {
          this.updateDetectUI(data.detect)
        }
        break
      case 'update_identity_search':
        if (data.detect_id && data.identity_search) {
          const detectElement = document.getElementById(`detect-${data.detect_id}`)
          if (detectElement) {
            const detect = JSON.parse(detectElement.dataset.detect || '{}')
            detect.identity_search = data.identity_search
            this.updateDetectUI(detect)

            // Clear expectation for identity search
            if (this.expectedResults[data.detect_id]) {
              this.expectedResults[data.detect_id].identitySearch = false
              if (!this.expectedResults[data.detect_id].identitySearch && !this.expectedResults[data.detect_id].audioIntelligence) {
                delete this.expectedResults[data.detect_id]
              }
            }
          } else {
            if (!this.pendingUpdates[data.detect_id]) {
              this.pendingUpdates[data.detect_id] = {}
            }
            this.pendingUpdates[data.detect_id].identitySearch = data.identity_search
          }
        }
        break
      case 'update_audio_intelligence':
        if (data.detect_id && data.audio_intelligence) {
          const detectElement = document.getElementById(`detect-${data.detect_id}`)
          if (detectElement) {
            const detect = JSON.parse(detectElement.dataset.detect || '{}')
            detect.audio_intelligence = data.audio_intelligence
            this.updateDetectUI(detect)

            // Clear expectation for audio intelligence
            if (this.expectedResults[data.detect_id]) {
              this.expectedResults[data.detect_id].audioIntelligence = false
              if (!this.expectedResults[data.detect_id].identitySearch && !this.expectedResults[data.detect_id].audioIntelligence) {
                delete this.expectedResults[data.detect_id]
              }
            }
          } else {
            if (!this.pendingUpdates[data.detect_id]) {
              this.pendingUpdates[data.detect_id] = {}
            }
            this.pendingUpdates[data.detect_id].audioIntelligence = data.audio_intelligence
          }
        }
        break
    }
  }

  applyPendingUpdates(detectId: string): void {
    if (this.pendingUpdates[detectId]) {
      const detectElement = document.getElementById(`detect-${detectId}`)
      if (detectElement) {
        const detect = JSON.parse(detectElement.dataset.detect || '{}')
        if (this.pendingUpdates[detectId].identitySearch) {
          detect.identity_search = this.pendingUpdates[detectId].identitySearch
        }
        if (this.pendingUpdates[detectId].audioIntelligence) {
          detect.audio_intelligence = this.pendingUpdates[detectId].audioIntelligence
        }
        this.updateDetectUI(detect)
      }
      delete this.pendingUpdates[detectId]
    }
  }

  updateDetectUI(detect: any): void {
    let detectElement = document.getElementById(`detect-${detect.uuid}`)
    const detectsList = document.getElementById('detects-list')

    if (!detectElement) {
      detectElement = document.createElement('li')
      detectElement.id = `detect-${detect.uuid}`
      detectElement.className = 'tw-flex tw-flex-col tw-mb-4 tw-bg-white tw-rounded-lg tw-overflow-hidden tw-shadow-md tw-border-l-8'
      detectsList?.prepend(detectElement)
    }

    detectElement.classList.remove('tw-border-green-500', 'tw-border-red-500')
    detectElement.classList.add(detect.metrics.label === 'real' ? 'tw-border-green-500' : 'tw-border-red-500')

    const expectations = this.expectedResults[detect.uuid] || { identitySearch: false, audioIntelligence: false }
    const isProcessingAdditionalInfo = (expectations.identitySearch && !detect.identity_search) || (expectations.audioIntelligence && !detect.audio_intelligence)
    const hasAdditionalInfo = detect.identity_search || detect.audio_intelligence

    detectElement.innerHTML = `
      <div class="tw-flex tw-items-center tw-justify-between tw-p-4">
        <div class="tw-flex tw-items-center">
          <button data-action="click->detect#togglePlayStop" data-index="${detect.uuid}" class="tw-mr-4 tw-bg-green-500 tw-p-3 tw-rounded-full hover:tw-bg-green-600 tw-text-white tw-text-xl tw-align-center">
            <i class="fas fa-play-circle"></i>
          </button>
          <div>
            <span class="tw-font-bold tw-truncate">${detect.filename || this.filename || 'Unknown file'}</span><br>
            <span class="tw-text-gray-600">${new Date(detect.created_at).toLocaleString()}</span>
          </div>
        </div>
        <div class="tw-flex tw-items-center">
          <span class="tw-bg-${detect.metrics.label === 'real' ? 'green' : 'red'}-500 tw-text-white tw-rounded tw-px-2 tw-py-1 tw-mr-2">${detect.metrics.label.charAt(0).toUpperCase() + detect.metrics.label.slice(1)}</span>
          <span class="tw-bg-gray-200 tw-rounded tw-px-2 tw-py-1 tw-mr-4">${detect.duration} seconds</span>
          <a href="/detect/${detect.uuid}" data-turbo-method="delete" data-turbo-confirm="Are you sure?" class="tw-text-gray-500 hover:tw-text-gray-700">
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
  
      <audio id="audio_${detect.uuid}" src="${detect.audio_url || this.audio_url}"></audio>
  
      ${
        isProcessingAdditionalInfo
          ? `
        <div class="tw-p-4">
          <span class="tw-text-blue-500 tw-flex tw-items-center">
            <i class="fas fa-spinner fa-spin tw-mr-2"></i> Loading additional information...
          </span>
        </div>
      `
          : hasAdditionalInfo
            ? `
        <div class="tw-p-4">
          <button data-action="click->detect#toggleDetails" data-detect-id="${detect.uuid}" 
                  class="details-toggle tw-text-blue-500 tw-cursor-pointer tw-underline tw-flex tw-items-center"
                  aria-expanded="false" aria-controls="details-${detect.uuid}">
            Details
            <i class="fas fa-chevron-down tw-ml-1"></i>
          </button>
          <div id="details-${detect.uuid}" class="details-section">
            ${detect.identity_search ? this.renderIdentitySearch(detect.identity_search) : ''}
            ${detect.audio_intelligence ? this.renderAudioIntelligence(detect.audio_intelligence) : ''}
          </div>
        </div>
      `
            : ''
      }

  
      ${
        detect.metrics.score
          ? `
        <div class="tw-h-1 tw-rounded tw-overflow-hidden tw-w-full tw-flex">
          ${detect.metrics.score
            .map((score: number) => {
              const isReal = score < 0.5
              const shade = isReal ? (score < 0.1 ? 200 : score < 0.2 ? 400 : score < 0.3 ? 600 : score < 0.4 ? 800 : 900) : Math.ceil(score * 5) * 100
              const bgColor = isReal ? `tw-bg-emerald-${shade}` : `tw-bg-red-${shade}`
              return `<div style="width: ${100.0 / detect.metrics.score.length}%;" class="${bgColor} tw-h-full"></div>`
            })
            .join('')}
        </div>
      `
          : ''
      }
    `

    // Reconnect Stimulus controller to the new element
    this.application.getControllerForElementAndIdentifier(detectElement, 'detect')?.disconnect()
    this.application.getControllerForElementAndIdentifier(detectElement, 'detect')?.connect()

    detectElement.dataset.detect = JSON.stringify(detect)
    this.applyPendingUpdates(detect.uuid)
  }

  updateIdentitySearchUI(detectId: string, identitySearch: any) {
    const detailsContainer = document.getElementById(`details-${detectId}`)
    if (detailsContainer) {
      const identitySearchHtml = this.renderIdentitySearch(identitySearch)
      detailsContainer.innerHTML = identitySearchHtml + detailsContainer.innerHTML
    }
  }

  updateAudioIntelligenceUI(detectId: string, audioIntelligence: any) {
    const detailsContainer = document.getElementById(`details-${detectId}`)
    if (detailsContainer) {
      const audioIntelligenceHtml = this.renderAudioIntelligence(audioIntelligence)
      detailsContainer.innerHTML += audioIntelligenceHtml
    }
  }

  renderIdentitySearch(identitySearch: any): string {
    if (!identitySearch || !identitySearch.results) {
      return ''
    }

    const sortedResults = Object.entries(identitySearch.results)
      .sort(([, a], [, b]) => (b as any).distance - (a as any).distance)
      .slice(0, 3)

    const resultHtml = sortedResults
      .map(
        ([, identity]) => `
      <div class="tw-mb-2">
        <div class="tw-flex tw-justify-between tw-items-center tw-mb-1">
          <span class="tw-font-medium">${(identity as any).name}</span>
          <span class="tw-font-bold">${Math.max(0, Math.min((identity as any).distance, 100)).toFixed(2)}%</span>
        </div>
        <div class="tw-bg-gray-300 tw-h-2 tw-rounded-full">
          <div class="tw-bg-slate-500 tw-h-full tw-rounded-full" style="width: ${Math.max(0, Math.min((identity as any).distance, 100))}%;"></div>
        </div>
      </div>
    `,
      )
      .join('')

    return `
      <h3 class="tw-font-bold tw-text-lg tw-mb-2">Identity:</h3>
      <div class="tw-bg-gray-100 tw-p-4 tw-rounded-lg tw-mb-4">
        ${resultHtml}
      </div>
    `
  }

  renderAudioIntelligence(audioIntelligence: any): string {
    if (!audioIntelligence || !audioIntelligence.description) {
      return ''
    }

    // This function should be implemented in your JavaScript to match
    // the Ruby helper method's functionality
    const formattedDescription = this.formatAudioIntelligence(audioIntelligence.description)

    return `
      <h3 class="tw-font-bold tw-text-lg tw-mb-2">Audio Intelligence:</h3>
      <div class="tw-bg-gray-100 tw-p-4 tw-rounded-lg tw-text-sm audio-intelligence">
        ${formattedDescription}
      </div>
    `
  }

  // Helper method to format audio intelligence description
  formatAudioIntelligence(text: string): string {
    // Convert **bold** to <strong>bold</strong>
    text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')

    // Convert newlines to paragraph tags
    text = '<p>' + text.replace(/\n\n+/g, '</p><p>') + '</p>'

    // Convert single newlines within paragraphs to <br> tags
    text = text.replace(/([^\n])\n([^\n])/g, '$1<br>$2')

    // Convert bullet points to list items
    text = text.replace(/\* (.*)/g, '<li>$1</li>')

    // Wrap lists in <ul> tags
    text = text.replace(/(<li>.*<\/li>)/g, '<ul>$1</ul>')

    // Convert single-line headers (assuming they use : for headers)
    text = text.replace(/^(.*?):/, '<h4>$1:</h4>')

    return text
  }

  handleFileInputChange(event: Event): void {
    if (event.target instanceof HTMLInputElement && event.target.files) {
      if (event.target.files.length > 0) {
        const fileName = event.target.files[0].name
        this.fileNameTarget.textContent = fileName
        this.fileInputWrapperTarget.classList.add('tw-border-emerald-500')
        this.fileInputWrapperTarget.classList.remove('tw-border-gray-300')
      } else {
        this.fileNameTarget.textContent = 'Upload your audio (WAV or MP3)'
        this.fileInputWrapperTarget.classList.remove('tw-border-emerald-500')
        this.fileInputWrapperTarget.classList.add('tw-border-gray-300')
      }
    }
  }

  handleFormSubmit(e: Event): void {
    e.preventDefault()

    const formData = new FormData(this.formTarget)
    const file = formData.get('audio') as File
    if (file.size === 0) {
      return
    }

    // Delete the settings if they are not changed
    Object.keys(this.settingsChanged).forEach((key) => {
      if (this.settingsChanged[key as keyof typeof this.settingsChanged] === false) {
        formData.delete(key)
      }
    })

    this.filename = file.name
    this.audio_url = URL.createObjectURL(file)

    // Set expectations for identity search and audio intelligence
    const expectIdentitySearch = formData.get('search_identity') === '1'
    const expectAudioIntelligence = formData.get('audio_intelligence') === '1'

    this.openModal()
    fetch(this.formTarget.action, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success && typeof data.item.uuid === 'string') {
          // Set expectations for this detect
          this.expectedResults[data.item.uuid] = {
            identitySearch: expectIdentitySearch,
            audioIntelligence: expectAudioIntelligence,
          }
          this.pollForMetrics(data.item.uuid)
        } else {
          console.error('Error in processing: ', data.message)
          this.closeModal()
          Swal.fire('Error', 'Failed to process the audio!', 'error')
        }
      })
      .catch((error) => {
        console.error('Error:', error)
        this.closeModal()
        Swal.fire('Error', 'Something went wrong. Please try again', 'error')
      })
  }

  pollForMetrics(uuid: string): void {
    const pollInterval = 3000
    const pollFunction = () => {
      fetch(`/api/v2/detect/${uuid}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.success && data.item.metrics && data.item.metrics.label) {
            // this.updateUIWithMetrics(data.item)
            this.handleWebSocketMessage({ action: 'update_detect', detect: data.item })
            this.closeModal()
          } else {
            setTimeout(pollFunction, pollInterval)
          }
        })
        .catch((error) => {
          console.log(error)
          this.closeModal()
        })
    }
    pollFunction()
  }

  openModal(): void {
    document.body.classList.add('tw-bg-gray-500')
    this.modalTarget.classList.remove('tw-hidden')
    this.modalTarget.classList.add('tw-flex')
  }

  closeModal(): void {
    document.body.classList.remove('tw-bg-gray-500')
    this.modalTarget.classList.remove('tw-flex')
    this.modalTarget.classList.add('tw-hidden')
  }

  updateWithSlider(event: Event): void {
    const slider = event.target as HTMLInputElement
    const spanId = slider.dataset.spanId
    if (!spanId) {
      throw new Error('spanId is required')
    }
    const span = document.getElementById(spanId)
    if (span) {
      span.innerText = slider.value + (spanId === 'sensitivityValue' ? '%' : '')
    }
  }

  toggleAdvancedSettings(): void {
    const advancedSettings = this.advancedSettingsTarget
    const icon = this.advancedSettingsIconTarget as HTMLElement // Ensure you declare this target in your controller
    advancedSettings.classList.toggle('tw-hidden')
    if (advancedSettings.classList.contains('tw-hidden')) {
      icon.classList.remove('fa-chevron-up')
      icon.classList.add('fa-chevron-down')
    } else {
      icon.classList.remove('fa-chevron-down')
      icon.classList.add('fa-chevron-up')
    }
  }

  toggleVoiceIsolation(): void {
    const toggleBg = document.querySelector('.toggle-bg')
    this.checkboxTarget.checked = !this.checkboxTarget.checked
    if (this.checkboxTarget.checked) {
      this.toggleSwitchTarget.style.transform = 'translateX(90%)'
      toggleBg?.classList.remove('tw-bg-gray-200')
      toggleBg?.classList.add('tw-bg-green-500')
    } else {
      this.toggleSwitchTarget.style.transform = 'translateX(0)'
      toggleBg?.classList.remove('tw-bg-green-500')
      toggleBg?.classList.add('tw-bg-gray-200')
    }
  }

  toggleSearchIdentity(): void {
    const toggleBg = this.searchIdentityCheckboxTarget.nextElementSibling
    this.searchIdentityCheckboxTarget.checked = !this.searchIdentityCheckboxTarget.checked
    if (this.searchIdentityCheckboxTarget.checked) {
      this.searchIdentityToggleSwitchTarget.style.transform = 'translateX(90%)'
      toggleBg?.classList.remove('tw-bg-gray-200')
      toggleBg?.classList.add('tw-bg-green-500')
      this.settingsChanged.search_identity = true
    } else {
      this.searchIdentityToggleSwitchTarget.style.transform = 'translateX(0)'
      toggleBg?.classList.remove('tw-bg-green-500')
      toggleBg?.classList.add('tw-bg-gray-200')
      this.settingsChanged.search_identity = false
    }
  }

  toggleAudioIntelligence(): void {
    const toggleBg = this.audioIntelligenceCheckboxTarget.nextElementSibling
    this.audioIntelligenceCheckboxTarget.checked = !this.audioIntelligenceCheckboxTarget.checked
    if (this.audioIntelligenceCheckboxTarget.checked) {
      this.audioIntelligenceToggleSwitchTarget.style.transform = 'translateX(90%)'
      toggleBg?.classList.remove('tw-bg-gray-200')
      toggleBg?.classList.add('tw-bg-green-500')
      this.settingsChanged.audio_intelligence = true
    } else {
      this.audioIntelligenceToggleSwitchTarget.style.transform = 'translateX(0)'
      toggleBg?.classList.remove('tw-bg-green-500')
      toggleBg?.classList.add('tw-bg-gray-200')
      this.settingsChanged.audio_intelligence = false
    }
  }

  toggleDetails(event: Event) {
    const detailsId = (event.currentTarget as HTMLElement).dataset.detectId
    const detailsElement = document.getElementById(`details-${detailsId}`)
    const toggleButton = event.currentTarget

    if (detailsElement) {
      detailsElement.classList.toggle('expanded')
      const isExpanded = detailsElement.classList.contains('expanded')
      if (toggleButton instanceof HTMLElement) {
        toggleButton.classList.toggle('expanded')
        toggleButton.setAttribute('aria-expanded', isExpanded.toString())
      }
    }
  }

  togglePlayStop(event: Event): void {
    const clickTarget = event.target as HTMLElement
    const button = clickTarget?.closest('button') as HTMLButtonElement // click could be on the icon or the button

    const index = button.dataset.index
    const audio = document.getElementById(`audio_${index}`) as HTMLAudioElement
    const playIcon = button.querySelector('i')

    if (audio.paused) {
      audio.play()
      playIcon?.classList.remove('fa-play-circle')
      playIcon?.classList.add('fa-stop-circle')
      button.classList.add('tw-bg-green-600')
      button.classList.remove('tw-bg-green-500')
    } else {
      audio.pause()
      audio.currentTime = 0
      playIcon?.classList.add('fa-play-circle')
      playIcon?.classList.remove('fa-stop-circle')
      button.classList.remove('tw-bg-green-600')
      button.classList.add('tw-bg-green-500')
    }
  }

  updateUIWithMetrics(detectData: any): void {
    console.log(detectData)
    this.updateDetectUI(detectData)
    // window.location.reload()
  }
}
