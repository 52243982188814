/* eslint-disable react-hooks/rules-of-hooks */
import { Controller } from '@hotwired/stimulus'
import { useWindowResize } from 'stimulus-use'

import { BrowserUtils } from '@/src/utils/BrowserUtils'

/**
 * This controller manages the visibility of an element based on the window size.
 * It uses the `useWindowResize` hook from `stimulus-use` to listen for window resize events.
 * When the window is resized, it checks if the device is mobile using `BrowserUtils.isMobile()`.
 * If the device is mobile, it hides the element by adding the 'tw-hidden' class.
 * If the device is not mobile, it ensures the element is visible by removing the 'tw-hidden' class.
 */
export default class extends Controller {
  /**
   * Called when the controller is connected to the DOM.
   * Sets up the window resize listener.
   */
  connect() {
    useWindowResize(this)
  }

  /**
   * Called when the window is resized.
   * Hides the element if the device is mobile, otherwise shows the element.
   */
  windowResize() {
    if (BrowserUtils.isMobile()) {
      if (!this.element.classList.contains('tw-hidden')) {
        this.element.classList.add('tw-hidden')
      }
    } else {
      this.element.classList.remove('tw-hidden')
    }
  }
}

// usage:
// <% unless @is_onboarding_needed %>
// <div  data-controller="show-on-desktop" class="">
