import { Controller } from '@hotwired/stimulus'
import React from 'react'
import ReactDOM from 'react-dom'

import { CodeViewer } from '../src/components/ClipEditor/RichTextEditor/Blocks/Voice/CodeViewer'

interface CodeViewerControllerElement extends HTMLElement {
  dataset: {
    voiceUuid: string
    voiceName: string
    voiceType: string
    voiceDefaultLanguage: string
    voiceApiSupport: string
  }
}

export default class extends Controller<CodeViewerControllerElement> {
  static targets = ['modal']

  private modalContent: string = ''

  connect(): void {
    this.modalContent = `
      <div class="tw-fixed tw-inset-0 tw-bg-gray-600 tw-bg-opacity-50 tw-overflow-y-auto tw-h-full tw-w-full tw-z-50" id="code-viewer-modal">
        <div class="tw-relative tw-top-20 tw-mx-auto tw-p-5 tw-border tw-w-11/12 tw-max-w-4xl tw-shadow-lg tw-rounded-md tw-bg-white">
            <div class="tw-mt-3">
                <h3 class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900 tw-text-center">API Code</h3>
                <div class="tw-mt-2 tw-px-7 tw-py-3">
                    <div id="code-viewer-container"></div>
                </div>
                <div class="tw-items-center tw-px-4 tw-py-3">
                    <button id="close-modal" class="tw-px-4 tw-py-2 tw-bg-gray-500 tw-text-white tw-text-base tw-font-medium tw-rounded-md tw-w-full tw-shadow-sm hover:tw-bg-gray-600 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-300">
                        Close
                    </button>
                </div>
            </div>
        </div>
      </div>
    `
  }

  openModal(event: Event): void {
    const voiceUuid = (event.currentTarget as CodeViewerControllerElement).dataset.voiceUuid
    const voiceName = (event.currentTarget as CodeViewerControllerElement).dataset.voiceName
    const voiceType = (event.currentTarget as CodeViewerControllerElement).dataset.voiceType
    const voiceDefaultLanguage = (event.currentTarget as CodeViewerControllerElement).dataset.voiceDefaultLanguage
    const voiceApiSupport = (event.currentTarget as CodeViewerControllerElement).dataset.voiceApiSupport

    document.body.insertAdjacentHTML('beforeend', this.modalContent)

    const closeButton = document.getElementById('close-modal')
    if (closeButton) {
      closeButton.addEventListener('click', () => this.closeModal())
    }

    const option = {
      uuid: voiceUuid,
      name: voiceName,
      type: voiceType,
      default_language: voiceDefaultLanguage,
      api_support: JSON.parse(voiceApiSupport),
      source: 'resemble',
    }
    this.renderCodeViewer(voiceUuid, [option])
  }

  closeModal(): void {
    const modal = document.getElementById('code-viewer-modal')
    if (modal) {
      modal.remove()
    }
  }

  renderCodeViewer(voiceUuid: string, voices: any): void {
    const container = document.getElementById('code-viewer-container')
    if (container) {
      const fetchClipBody = async () => {
        return '<voice>Write your content here.</voice>'
      }

      ReactDOM.render(
        React.createElement(CodeViewer, {
          voiceUuid: voiceUuid,
          projectUuid: null as unknown as string,
          clipBodyPromise: fetchClipBody(),
          voices: voices,
          location: 'VoiceCard',
          showTabs: ['direct sync', 'streaming'],
        }),
        container,
      )
    }
  }
}
