import { cable } from '@hotwired/turbo-rails'

/**
 * Mount the cable to the app object for the react apps to use
 */
App = window.App || (window.App = {})

cable.getConsumer().then((consumer) => {
  App.cable = consumer
})
