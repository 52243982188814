/* eslint-disable react-hooks/rules-of-hooks */
import { Controller } from '@hotwired/stimulus'
import { unmountComponentAtNode } from 'react-dom'

// --------------------------------------------------------------------------
// Sample Usage
// ----------------------------------------------------------------------------

// 1. Attach mountApp... function in your entrypoint to window object e.g window.mountMainEditor = mountMainEditor
// 2. Add data-controller="spa" and data-spa-target="TARGET_NAME" to the root element in the view file where the react app is going to be attached
// 3. Add [TARGET_NAME]targetConnected and [TARGET_NAME]targetDisconnected methods to the controller (we are using Stimulus,
// these methods are called automatically when the target is connected or disconnected to the DOM by a MutationObserver behind the scenes)
// 4. In the [TARGET_NAME]targetConnected method, call this.mountApp(window.mount[TARGET_NAME]) to mount the react app
// 5. In the [TARGET_NAME]targetDisconnected method, call unmountComponentAtNode(element) to unmount the react app. When using react 18,
// we will probably attach a window.unmountApp from the entrypoint file and call it inside the [TARGET_NAME]targetDisconnected method.
// 6. Disconnection hook is not needed if the component does not need to be unmounted. e.g getStartedVideos. In this case
// the component can be safely mounted during turbo preview phase.

// Simply using connect is not an option as in that case we will have to preload all the apps in one go (importing all the entrypoints
// in this file) drastically affecting load times and hurting UX. I believe this is worth it as entry-points rarely change.

// Note: Turbo is still going to cache the entire app, but now sice we remount the components, we will have a fresh state every time
// ---------------------------------------------------------------------------

if (
  !new (class {
    x: any
    // eslint-disable-next-line no-prototype-builtins
  })().hasOwnProperty('x') // https://mobx.js.org/installation.html#use-spec-compliant-transpilation-for-class-properties
) {
  throw new Error('Transpiler is not configured correctly')
}

export default class extends Controller {
  static targets = [
    'getStartedVideos',
    'notifications',
    'toasts',
    'slim',
    'mainEditor',
    'voiceCloning',
    'voiceRecorder',
    'presetCreator',
    'playground',
    'editorShare',
    'demoRecorder',
    'voiceStylesForm',
    'newHub',
    'playgroundShare',
  ]

  connect() {
    async function enableMocking() {
      if (process.env.NODE_ENV === 'development' && process.env.API_MOCKING_ENABLED === 'true') {
        const { worker } = await import('../mocks/browser')
        return worker.start({ onUnhandledRequest: 'bypass' })
      }
      return Promise.resolve()
    }

    /**
     * Custom hook used to identify if all requirements to load a react app are met. This replaces
     * jquery's "ready" function which is unsupported by turbo rails
     * @param rootElementId The id of the element to mount the react app to (e.g. #app)
     * @param mountReactFn The function to call when the react app can be mounted. This function should contain
     *                      the ReactDOM.render call.
     * @param requireActionCable True if rails action cable has to be loaded as well for the react app to mount. False otherwise.
     */
    window.mountReactWhenReady = function (rootElementId, mountReactFn, requireActionCable) {
      const app = document.querySelector(rootElementId)
      if (app === null) {
        return
      }

      const mountReactApp = () => {
        if (process.env.NODE_ENV === 'development') {
          try {
            enableMocking()
              .then(() => mountReactFn(app))
              .catch(() => mountReactFn(app))
          } catch (error) {}
        } else {
          mountReactFn(app)
        }
      }

      if (!requireActionCable) {
        mountReactApp()
        return
      }

      const delayTime = 100
      const maxRecursions = 100
      const checkIfRequirementsLoaded = (totalRecursions: number) => {
        if (totalRecursions > maxRecursions) {
          console.error("Error mounting react app for. We've attempted to load ", rootElementId, 'for ' + (delayTime * maxRecursions) / 1000 + ' seconds.')
          return
        }
        setTimeout(function () {
          if (window.App.cable === undefined && requireActionCable) {
            // If the requirements haven't loaded yet, then recursively call this function until they have
            checkIfRequirementsLoaded(totalRecursions + 1)
          } else {
            mountReactApp()
          }
        }, delayTime)
      }
      checkIfRequirementsLoaded(0)
    }
  }

  slimTargetConnected() {
    this.mountApp(window.mountOnboardingEditor)
  }
  slimTargetDisconnected(element: Element) {
    unmountComponentAtNode(element)
  }

  newHubTargetConnected() {
    this.mountApp(window.mountNewHub)
  }
  newHubTargetDisconnected(element: Element) {
    unmountComponentAtNode(element)
  }

  stsHubTargetConnected() {
    this.mountApp(window.mountStsHub)
  }
  stsHubTargetDisconnected(element: Element) {
    unmountComponentAtNode(element)
  }

  playgroundShareConnected() {
    this.mountApp(window.mountPlaygroundShare)
  }
  playgroundShareDisconnected(element: Element) {
    unmountComponentAtNode(element)
  }

  getStartedVideosTargetConnected() {
    this.mountApp(window.mountGetStartedVideos, true)
  }

  notificationsTargetConnected() {
    this.mountApp(window.mountNotifications, true)
  }

  toastsTargetConnected() {
    this.mountApp(window.mountToasts)
  }

  toastsTargetDisconnected(element: Element) {
    unmountComponentAtNode(element)
  }

  voiceCloningTargetConnected() {
    this.mountApp(window.mountVoiceCloning)
  }
  voiceCloningTargetDisconnected(element: Element) {
    unmountComponentAtNode(element)
  }

  mainEditorTargetConnected() {
    this.mountApp(window.mountMainEditor)
  }
  mainEditorTargetDisconnected(element: Element) {
    unmountComponentAtNode(element)
  }

  presetCreatorTargetConnected() {
    this.mountApp(window.mountPresetCreator)
  }
  presetCreatorTargetDisconnected(element: Element) {
    unmountComponentAtNode(element)
  }

  playgroundTargetConnected() {
    this.mountApp(window.mountPlayground)
  }
  playgroundTargetDisconnected(element: Element) {
    unmountComponentAtNode(element)
  }

  editorShareTargetConnected() {
    this.mountApp(window.mountEditorShare)
  }
  editorShareTargetDisconnected(element: Element) {
    unmountComponentAtNode(element)
  }

  demoRecorderTargetConnected() {
    this.mountApp(window.mountDemoRecorder)
  }
  demoRecorderTargetDisconnected(element: Element) {
    unmountComponentAtNode(element)
  }

  voiceStylesFormTargetConnected() {
    this.mountApp(window.mountVoiceStylesForm)
  }
  voiceStylesFormTargetDisconnected(element: Element) {
    unmountComponentAtNode(element)
  }

  voiceRecorderTargetConnected() {
    this.mountApp(window.mountVoiceRecorder)
  }
  voiceRecorderTargetDisconnected(element: Element) {
    unmountComponentAtNode(element)
  }

  mountApp(mountFn: () => void, mountDuringTurboPreview: boolean = false) {
    try {
      // Don't mount while Turbo is showing a preview, because when the preview is replaced with the real page,
      // the app will be mounted again and cause a flicker
      if (!document.documentElement.hasAttribute('data-turbo-preview') || mountDuringTurboPreview) {
        mountFn()
      }
    } catch (error) {
      // mounting for the first time, ignore. (the vite_javascript_tag wouldn't have loaded the script yet, only the container was visible)
    }
  }
}
