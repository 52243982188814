import { Controller } from '@hotwired/stimulus'

// Sends analytics events to backend
//
export default class extends Controller {
  static targets = ['analyticsLink', 'analyticsEvent']

  declare readonly analyticsLinkTargets: HTMLAnchorElement[]
  declare readonly analyticsEventTargets: HTMLElement[]
  declare readonly hasAnalyticsLinkTarget: boolean
  declare readonly hasAnalyticsEventTarget: boolean

  connect(): void {
    if (this.hasAnalyticsLinkTarget) {
      this.analyticsLinkTargets.forEach((target) => {
        target.addEventListener('click', this.trackLinkClick.bind(this))
      })
    }
    if (this.hasAnalyticsEventTarget) {
      this.analyticsEventTargets.forEach((target) => {
        target.addEventListener('click', this.trackEvent.bind(this))
      })
    }
  }

  trackLinkClick(event: MouseEvent) {
    event.preventDefault()
    const target = event.currentTarget as HTMLAnchorElement
    const url = target.href
    const eventName = target.getAttribute('data-userevents-event-name') || 'link_click'

    const data = new FormData()
    data.append('eventName', eventName)
    data.append('authenticity_token', this.getCSRFToken())

    // Submit to beacon API
    navigator.sendBeacon('/analytics', data)

    // Use setTimeout to ensure the beacon is sent before navigating away
    setTimeout(() => {
      window.location.href = url
    }, 100)
  }

  trackEvent(event: MouseEvent) {
    const target = event.currentTarget as HTMLElement
    const eventName = target.getAttribute('data-userevents-event-name') || ''
    const additionalData = JSON.parse(target.getAttribute('data-userevents-additional-data') || '{}')

    const data = new FormData()
    data.append('eventName', eventName)
    data.append('authenticity_token', this.getCSRFToken())

    // Append additional data to the FormData
    Object.entries(additionalData).forEach(([key, value]) => {
      data.append(key, String(value))
    })

    // Submit to beacon API
    navigator.sendBeacon('/analytics', data)
  }

  private getCSRFToken() {
    // @ts-ignore
    return document.querySelector('meta[name="csrf-token"]').content
  }
}
