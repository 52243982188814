import '@fontsource-variable/inter'
// polyfills
import 'core-js/actual/array/to-sorted'
import 'bootstrap'
import './styles.css'

import { start } from '@rails/activestorage'
import $ from 'jquery'

start()
/**
 * Bind jquery, popper, and turbo to the window object
 */
window.jQuery = $
window.$ = $

/**
 * Initialize any other javascript resources. This is done after binding jquery as the following
 * resources may require them.
 */
import '@/vendors'
import '@/channels'
import '@/controllers'

// https://vitejs.dev/guide/build.html#load-error-handling
window.addEventListener('vite:preloadError', () => {
  window.location.reload()
})
